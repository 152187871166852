<script>
import IconColorMixin from '@/mixins/IconColorMixin';

export default defineNuxtComponent({
  mixins: [IconColorMixin],
  props: {
    iconName: {
      type: String,
      default: '',
    },
    size: {
      type: [Number, String],
      default: 24,
    },
    accent: {
      type: String,
      default: 'currentColor',
    },
    darkMode: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 12V13C21.5523 13 22 12.5523 22 12H21ZM21 16H22C22 15.4477 21.5523 15 21 15V16ZM6 16V15C5.44772 15 5 15.4477 5 16H6ZM6 12H5C5 12.5523 5.44772 13 6 13V12ZM8 8C6.34315 8 5 9.34315 5 11H7C7 10.4477 7.44772 10 8 10V8ZM19 8H8V10H19V8ZM22 11C22 9.34315 20.6569 8 19 8V10C19.5523 10 20 10.4477 20 11H22ZM22 12V11H20V12H22ZM20 14C20 13.4477 20.4477 13 21 13V11C19.3431 11 18 12.3431 18 14H20ZM21 15C20.4477 15 20 14.5523 20 14H18C18 15.6569 19.3431 17 21 17V15ZM22 17V16H20V17H22ZM19 20C20.6569 20 22 18.6569 22 17H20C20 17.5523 19.5523 18 19 18V20ZM8 20H19V18H8V20ZM5 17C5 18.6569 6.34315 20 8 20V18C7.44772 18 7 17.5523 7 17H5ZM5 16V17H7V16H5ZM7 14C7 14.5523 6.55228 15 6 15V17C7.65685 17 9 15.6569 9 14H7ZM6 13C6.55228 13 7 13.4477 7 14H9C9 12.3431 7.65685 11 6 11V13ZM5 11V12H7V11H5Z"
      :fill="getBaseColor(darkMode)"
    />
    <path
      d="M18 6C18.5523 6 19 5.55228 19 5C19 4.44772 18.5523 4 18 4V6ZM1 9C1 9.55228 1.44772 10 2 10C2.55228 10 3 9.55228 3 9H1ZM4 6H18V4H4V6ZM3 9V7H1V9H3ZM4 4C2.34315 4 1 5.34315 1 7H3C3 6.44772 3.44772 6 4 6V4Z"
      :fill="accent"
    />
  </svg>
</template>
